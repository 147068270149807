import { Component, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ngxCsv } from 'ngx-csv';
import { NgbdAdvancedSortableHeader } from "src/app/shared/directives/sortable.directive";
import { APIURLPATH } from 'src/app/shared/services/url-path';
import { UserMgtService } from 'src/app/user-management/services/user-mgt.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-permit-type',
  templateUrl: './permit-type.component.html',
  styleUrls: ['./permit-type.component.scss']
})
export class PermitTypeComponent {
  userForm!: UntypedFormGroup;
  submitted = false;
  lists: any[] = [];
  total!: number;
  @ViewChildren(NgbdAdvancedSortableHeader)
  headers!: QueryList<NgbdAdvancedSortableHeader>;
  page = 1;
  pageSize = 20;
  startIndex = 0;
  endIndex = 9;
  totalRecords = 0;
  collectionSize = 0;
  content?: any;
  econtent: any = {};
  masterSelected!: boolean;
  outPutData: any = {};
  roles: any[] = []

  constructor(
    private modalService: NgbModal,
    public userService: UserMgtService,
    private formBuilder: UntypedFormBuilder,
  ) {
    this.initiliazeForm();
    this.loadList();
  }

  ngOnDestroy() {
    this.userService.searchTerm = '';
  }

  loadList() {
    this.userService
      .findAllWithPath(environment.applicationUrl + APIURLPATH.PERMITYPES)
      .subscribe(
        (data) => {
          this.lists = data;
          this.content = data;
          setTimeout(() => {
            document.getElementById("elmLoader")?.classList.add("d-none");

          }, 1200);
          this.collectionSize = this.lists.length;
          this.total = this.lists.length;
          this.totalRecords = this.lists.length;
          this.startIndex = (this.page - 1) * this.pageSize + 1;
          this.endIndex = (this.page - 1) * this.pageSize + this.pageSize;
          if (this.endIndex > this.totalRecords) {
            this.endIndex = this.totalRecords;
          }
          this.lists = this.lists.slice(
            this.startIndex - 1,
            this.endIndex
          );
        });
  }


  ngOnInit(): void {
    this.outPutData.name = 'Permit Type';
  }


  initiliazeForm() {
    this.userForm = this.formBuilder.group({
      id: [""],
      name: ['', [Validators.required]],
      code: ['', [Validators.required]],
    });
  }

  get form() {
    return this.userForm.controls;
  }



  openModal(content: any) {
    this.outPutData.isOpen = true;
    this.outPutData.type = 'add';
    this.outPutData.name = 'Permit Type';
    this.outPutData.url = environment.applicationUrl + APIURLPATH.PERMITYPES;
    this.outPutData.method = '0';
    this.initiliazeForm()
    this.outPutData.userForm = this.userForm;
    this.submitted = false;
    this.modalService.open(content, { size: "md", centered: true });
  }

  checkedValGet: any[] = [];
  onCheckboxChange(e: any) {
    var checkedVal: any[] = [];
    var result;
    for (var i = 0; i < this.lists.length; i++) {
      if (this.lists[i].state == true) {
        result = this.lists[i];
        checkedVal.push(result);
      }
    }
    this.checkedValGet = checkedVal;
    checkedVal.length > 0
      ? ((
        document.getElementById("remove-actions") as HTMLElement
      ).style.display = "block")
      : ((
        document.getElementById("remove-actions") as HTMLElement
      ).style.display = "none");
  }


  getPremiumData() {

    this.lists = this.content
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);

  }


  editDataGet(id: any, content: any) {
    this.submitted = false;

    this.modalService.open(content, { size: "md", centered: true });
    // var modelTitle = document.querySelector(".modal-title") as HTMLAreaElement;
    // modelTitle.innerHTML = "Edit User";
    // var updateBtn = document.getElementById("add-btn") as HTMLAreaElement;
    // updateBtn.innerHTML = "Update";

    this.userService.findOneWithPath(id, environment.applicationUrl + APIURLPATH.PERMITYPES).subscribe({
      next: (data: any) => {
        const users = data;
        this.econtent = users;
        this.userForm.controls['code'].setValue(this.econtent.code);
        this.userForm.controls['name'].setValue(this.econtent.name);
        this.userForm.controls["id"].setValue(this.econtent.id);
        this.outPutData.userForm = this.userForm;
        this.outPutData.type = 'edit';
        this.outPutData.name = 'Permit Type';
        this.outPutData.url = environment.applicationUrl + APIURLPATH.PERMITYPES;
        // this.outputData.isOpen = true;
        this.outPutData.isOpen = true;

      },
    });
  }


  deleteData(id: any) {
    if (id) {
      this.userService.deleteWithPath(id, environment.applicationUrl + APIURLPATH.PERMITYPES).subscribe({
        next: data => { this.loadList(); },
        error: err => {
        }
      });

    } else {

    }
  }


  deleteMultiple(content: any) {
    var checkboxes: any = document.getElementsByName("checkAll");
    var result;
    var checkedVal: any[] = [];
    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].checked) {
        result = checkboxes[i].value;
        checkedVal.push(result);
      }
    }
    if (checkedVal.length > 0) {
      this.modalService.open(content, { centered: true });
    } else {
      Swal.fire({
        text: "Please select at least one checkbox",
        confirmButtonColor: "#239eba",
      });
    }
    this.checkedValGet = checkedVal;
  }

  checkUncheckAll(ev: any) {
    this.lists.forEach((x: { state: any }) => (x.state = ev.target.checked));
    var checkedVal: any[] = [];
    var result;
    for (var i = 0; i < this.lists.length; i++) {
      if (this.lists[i].state == true) {
        result = this.lists[i];
        checkedVal.push(result);
      }
    }
    this.checkedValGet = checkedVal;
    checkedVal.length > 0
      ? ((
        document.getElementById("remove-actions") as HTMLElement
      ).style.display = "block")
      : ((
        document.getElementById("remove-actions") as HTMLElement
      ).style.display = "none");
  }


  saveValue(event: any) {
    this.loadList();
    this.modalService.dismissAll();
  }



  // Csv File Export
  csvFileExport() {
    var users = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalseparator: ".",
      showLabels: true,
      showTitle: true,
      title: "Build Type Data",
      useBom: true,
      noDownload: false,
      headers: [
        "ID",
        "name",
        "code",
      ],
    };
    new ngxCsv(this.content, "role", users);
  }
}
