<div class="stepper-content">
    <div class="stepper-body">
        <div>
            <div class="step-panel">
                <form [formGroup]="userForm" (ngSubmit)="onSubmit()">
                    <div class="kbk-updloadtable">
                        <ul class="updload-list">
                            <div class="updload-list_header">
                                <li class="updload-list_item">
                                    <div class="updload-list_item-dt hsv">
                                        <span class="text">Additional Documents</span>
                                    </div>
                                    <div class="updload-list_item-dt">
                                        <span class="text">Uploaded Documents</span>
                                    </div>
                                </li>
                            </div>
                            <div class="updload-list_body">
                                <li class="updload-list_item">
                                    <div class="updload-list_item-dt">
                                        <div class="hsv">
                                            <span class="text">Additional File</span>
                                            <span class="kbk-warn"><img src="assets/ikons/colored/ikon-warn-o.svg"> Only
                                                PDF files are allowed. Except for BoQ</span>
                                        </div>
                                        <label *ngIf="!pageController.fileSubmit"
                                            class="kbk-btn kbk-btn-sm kbk-btn-sec">
                                            <input type="file" multiple (change)="getFileDetails($event)" id="document"
                                                name="document" formControlName="document" style="display: none;">
                                            Upload </label>
                                        <div class="nofile">
                                            <span class="icon">
                                                <img src="assets/ikons/colored/ikon-file-orange.svg" alt="" />
                                            </span>
                                            <span class=" text">{{fileData.fileNameDisplay ? fileData.fileNameDisplay : 'no file'}}</span>
                                        </div>
                                        <label *ngIf="!pageController.fileSubmit"
                                            class="kbk-btn kbk-btn-sm kbk-btn-sec">
                                            <span>-</span>
                                        </label>
                                        <label *ngIf="pageController.fileSubmit" class="kbk-btn kbk-btn-sm kbk-btn-sec">
                                            <input type="file" style="display: none;"> Wait... </label>
                                    </div>
                                </li>
                                <li class="updload-list_item" *ngFor="let dr of pageController.documents">
                                    <div class="updload-list_item-dt">
                                        <div class="hsv">
                                            <span class="text">{{dr.additionalDescription}}</span>
                                            <span class="kbk-warn"><img src="assets/ikons/colored/ikon-warn-o.svg"> Only
                                                PDF files are allowed. Except for BoQ</span>
                                        </div>
                                        <!-- <label *ngIf="!pageController.fileSubmit && !dr.uploaded"
                                            class="kbk-btn kbk-btn-sm kbk-btn-sec">
                                            <input type="file" multiple id="document"
                                                name="document" formControlName="document" style="display: none;">
                                            Upload </label> -->
                                        <label class="kbk-btn kbk-btn-sm kbk-btn-sec"
                                            (click)="viewDocument(viewContent, dr, 'ap-stas')"> View </label>
                                    </div>
                                    <div class="updload-list_item-dt">
                                        <div *ngIf="dr">
                                            <span class="icon">
                                                <img src="assets/ikons/colored/ikon-file-orange.svg" alt="" />
                                            </span>
                                            <span class="text">{{dr?.fileName | titlecase}}</span>
                                        </div>
                                        <div class="nofile" *ngIf="!dr">
                                            <span class="icon">
                                                <img src="assets/ikons/colored/ikon-file-orange.svg" alt="" />
                                            </span>
                                            <span class=" text">no file</span>
                                        </div>
                                        <button *ngIf="dr && this.inputData.applicationStatus.code !== 'CTFD'" 
                                        class="kbk-btn kbk-btn-sm kbk-btn-error" type="button"
                                            data-bs-target="#showModal"
                                            (click)="openDeleteModal(deleteModel, 'md', dr)">Remove</button>
                                    </div>
                                </li>
                            </div>
                        </ul>
                    </div>
                    <div class="step-panel_footer">
                        <!-- <button (click)="onPrev()">Previous</button>
                        <button type="button" *ngIf="pageController.submitted"> Wait... </button>
                        
                        <button data-bs-toggle="modal" type="button" data-bs-target="#showModal"
                            *ngIf="pageController.isReadyToSubmit && !pageController.submitted"
                            (click)="openModal(isAcceptThatTheInformationProvided, 'md')"> {{resubmitData.isResubmit ?
                            'Resubmit' : 'Submit' }} </button> -->
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<ng-template #viewContent role="document" let-modal>
    <div class="modol-header">
        <h2 class="exampleModalLabel">View Document</h2>
        <!-- <span class="caption">Fill required input to create new user</span> -->
        <button type="button" class="kbk-btn kbk-btn-sec kbk-btn-close" data-bs-dismiss="modal" aria-label="Close"
            id="close-modal" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modo-contain" *ngIf="outputData">
        <app-view-document [inputData]="outputData"></app-view-document>
    </div>
</ng-template>
<ng-template #deleteModel let-modal>
    <div class="modal-content">
        <div class="modol-header">
            <h2 id="exampleModalLabel">You are about to delete a {{pageController?.deleteData?.fileName | titlecase}} ?
            </h2>
            <p>Deleting your {{pageController?.deleteData.name | titlecase}} will remove all of your information from
                our database. </p>
            <button type="button" class="kbk-btn kbk-btn-sec kbk-btn-close" data-bs-dismiss="modal" aria-label="Close"
                id="btn-close" (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
            <div class="mt-md">
                <!-- <h4>You are about to delete a {{deleteData.fileName}} ?</h4> -->
            </div>
            <div class="kbk-x-c sp-sm mt-md">
                <button type="button" class="kbk-btn kbk-btn-sec" data-bs-dismiss="modal"
                    (click)="modal.close('Close click')" id="deleteRecord-close">Close</button>
                <button *ngIf="!pageController.isDeleting" type="button" class="kbk-btn kbk-btn-error"
                    id="delete-product" (click)="deleteFile()">Yes, Delete!</button>
                <button *ngIf="pageController.isDeleting" type="button" class="kbk-btn kbk-btn-error"
                    id="delete-product">Application is deleting file...</button>
            </div>
        </div>
    </div>
</ng-template>