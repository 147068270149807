<div class="stepper-content">
    <div class="stepper-header">
        <div class="upper-counter"> Step <span>1</span> / <span>4</span> </div>
    </div>
    <div class="stepper-body">
        <div *ngIf="!applicationDetail.isProjectAlreadyExists">
            <!-- Step content goes here -->
            <div class="step-panel">
                <div class="step-panel_header">
                    <h2>Plot information</h2>
                    <div class="kbk-x kbk-aend mt-md">
                        <div class="track-info">
                            <div class="track-profile">
                                <img src="assets/imgs/profile1.svg" alt="" />
                            </div>
                            <div class="track-dtail">
                                <label>UPI:</label>
                                <!-- <span class="track-user">{{applicationDetail.names}}</span> -->
                                <span class="track-usersub">{{applicationDetail?.upi}}</span>
                            </div>
                        </div>
                        <form (ngSubmit)="onNext()" [formGroup]="userForm" autocomplete="off">
                            <div class="form-input">
                                <label>PLOT Number / UPI</label>
                                <div class="inputgrp">
                                    <input type="text" id="upi" name="upi" formControlName="upi" readonly
                                        placeholder="(0/00/00/00/0000)">
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="step-panel_body">
                    <div class="result">
                        <div class="result-item">
                            <h3 class="mgnb">UPI Details</h3>
                            <div>
                                <div class="form-out">
                                    <label>Representative</label>
                                    <!-- <span>{{applicationDetail?.representative?.foreNames}}
                                        {{applicationDetail?.representative?.surname}}</span> -->
                                    <span>{{applicationDetail.owners[0].fullName}}</span>
                                </div>
                                <div class="form-out">
                                    <label>Provice</label>
                                    <span>{{applicationDetail?.representative?.address?.province?.provinceName}}</span>
                                </div>
                                <div class="form-out">
                                    <label>District</label>
                                    <span>{{applicationDetail?.representative?.address?.district?.districtName}}</span>
                                </div>
                                <div class="form-out">
                                    <label>Sector</label>
                                    <span>{{applicationDetail?.representative?.address?.sector?.sectorName}}</span>
                                </div>
                                <div class="form-out">
                                    <label>Cell</label>
                                    <span>{{applicationDetail?.representative?.address?.cell?.cellName}}</span>
                                </div>
                                <div class="form-out">
                                    <label>Village</label>
                                    <span>{{applicationDetail?.representative?.address?.village?.villageName}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="result-item">
                            <h3 class="mgnb">Parcel Details</h3>
                            <div>
                                <div class="form-out">
                                    <label>Apply for</label>
                                    <div class="kbk-x-s sp-xs">
                                        <!-- <span *ngFor="let a of applicationDetail?.zonings">{{a.use}}</span> -->
                                        <span>{{applicationDetail?.applicationName }}</span>
                                    </div>
                                    <!-- <span>Transport Zone</span> -->
                                </div>
                                <div class="form-out">
                                    <label>Provice</label>
                                    <span>{{applicationDetail?.parcelLocation?.province?.provinceName}}</span>
                                </div>
                                <div class="form-out">
                                    <label>District</label>
                                    <span>{{applicationDetail?.parcelLocation?.district?.districtName}}</span>
                                </div>
                                <div class="form-out">
                                    <label>Sector</label>
                                    <span>{{applicationDetail?.parcelLocation?.sector?.sectorName}}</span>
                                </div>
                                <div class="form-out">
                                    <label>Cell</label>
                                    <span>{{applicationDetail?.parcelLocation?.cell?.cellName}}</span>
                                </div>
                                <div class="form-out">
                                    <label>Village</label>
                                    <span>{{applicationDetail?.parcelLocation?.village?.villageName}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="result-map">
                            <h3 class="mgnb">Parcel location</h3>
                            <div>
                                <label>Coordinates</label>
                                <div class="map-wrapper">
                                    <google-map [options]="mapOptions">
                                        <map-marker [position]="marker.position"></map-marker>
                                    </google-map>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="step-panel_footer">
                    <!-- <button (click)="onPrev()" [disabled]="isPrevButtonDisabled()">Previous</button> -->
                    <button type="button" (click)="onPrev()">Cancel</button>
                    <button type="button" (click)="onNext()">Next</button>
                </div>
            </div>
        </div>
        <div *ngIf="applicationDetail.isProjectAlreadyExists">
            <!-- Step content goes here -->
            <div class="step-panel">
                <div class="step-panel_header">
                    <h2>Plot information</h2>
                    <div class="kbk-x kbk-aend mt-md">
                        <div class="track-info">
                            <div class="track-profile">
                                <img src="assets/imgs/profile1.svg" alt="" />
                            </div>
                            <div class="track-dtail">
                                <label>UPI:</label>
                                <!-- <span class="track-user">{{applicationDetail.names}}</span> -->
                                <span class="track-usersub">{{applicationDetail?.upi}}</span>
                            </div>
                        </div>
                        <form (ngSubmit)="onNext()" [formGroup]="userForm" autocomplete="off">
                            <div class="form-input">
                                <label>PLOT Number / UPI</label>
                                <div class="inputgrp">
                                    <input type="text" id="upi" name="upi" formControlName="upi" readonly
                                        placeholder="(0/00/00/00/0000)">
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="step-panel_body">
                    <div class="result">
                        <div class="result-item">
                            <h3 class="mgnb">UPI Details</h3>
                            <div>
                                <div class="form-out">
                                    <label>Representative</label>
                                    <span>{{applicationDetail?.foreNames}} {{applicationDetail?.surname}}</span>
                                </div>
                                <div class="form-out">
                                    <label>Provice</label>
                                    <span>{{applicationDetail?.provinceName}}</span>
                                </div>
                                <div class="form-out">
                                    <label>District</label>
                                    <span>{{applicationDetail?.districtName}}</span>
                                </div>
                                <div class="form-out">
                                    <label>Sector</label>
                                    <span>{{applicationDetail?.sectorName}}</span>
                                </div>
                                <div class="form-out">
                                    <label>Cell</label>
                                    <span>{{applicationDetail?.cellName}}</span>
                                </div>
                                <div class="form-out">
                                    <label>Village</label>
                                    <span>{{applicationDetail?.villageName}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="result-item">
                            <h3 class="mgnb">Parcel Details</h3>
                            <div>
                                <div class="form-out">
                                    <label>Apply for</label>
                                    <div class="kbk-x-s sp-xs">
                                        <!-- <span *ngFor="let a of applicationDetail?.zonings">{{a.use}}</span> -->
                                        <span>{{applicationDetail?.applicationName }}</span>
                                    </div>
                                    <!-- <span>Transport Zone</span> -->
                                </div>
                                <div class="form-out">
                                    <label>Provice</label>
                                    <span>{{applicationDetail?.provinceName}}</span>
                                </div>
                                <div class="form-out">
                                    <label>District</label>
                                    <span>{{applicationDetail?.districtName}}</span>
                                </div>
                                <div class="form-out">
                                    <label>Sector</label>
                                    <span>{{applicationDetail?.sectorName}}</span>
                                </div>
                                <div class="form-out">
                                    <label>Cell</label>
                                    <span>{{applicationDetail?.cellName}}</span>
                                </div>
                                <div class="form-out">
                                    <label>Village</label>
                                    <span>{{applicationDetail?.villageName}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="result-map">
                            <h3 class="mgnb">Parcel location</h3>
                            <div>
                                <label>Coordinates</label>
                                <div class="map-wrapper">
                                    <google-map [options]="mapOptions">
                                        <map-marker [position]="marker.position"></map-marker>
                                    </google-map>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="step-panel_footer">
                    <!-- <button (click)="onPrev()" [disabled]="isPrevButtonDisabled()">Previous</button> -->
                    <button type="button" (click)="onPrev()">Cancel</button>
                    <button type="button" (click)="onNext()">Next</button>
                </div>
            </div>
        </div>
    </div>
</div>