import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


import { TokenService } from 'src/app/auth-pages/services/token.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    // API endpoints that require additional headers
    private readonly apiList = [
        'auth/login',
        'auth/email/verify',
        'auth/forgot-password',
        'auth/reset-password',
        'auth/register',
        'auth/createStaff',
        'auth/resent-verification',
        'auth/change-password',
        'user-management/role',
        'user-management/users',
    ];

    // private readonly excludedApis = [environment.documentUrl + 'DocMgt/uploadAdditional'];


    private readonly excludedApis: string[] = [
        environment.documentUrl + 'DocMgt/uploadAdditional',
        environment.documentUrl + 'DocMgt/upload',
        environment.applicationUrl + 'approval/applicationApprovalCheckList',
        environment.applicationUrl + 'application/inspection/occupancy',
        environment.applicationUrl + 'application/inspection/foundationNotice',
    ];



    constructor(private tokenService: TokenService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Retrieve the current user token
        const currentUser: any = this.tokenService.getToken();
        const accessToken = currentUser?.data?.token?.access_token;

        // Check if the request URL matches any of the API list endpoints
        const isTargetApi = this.apiList.some((api) => request.url.includes(api));



        // const isExcludedApi = this.excludedApis.some((url) => request.url === url);
        const isExcludedApi = this.excludedApis.some((url) => request.url.startsWith(url));


        if (isExcludedApi) {
            console.log('Excluded API, skipping token addition:', request.url);
            return next.handle(request); // Pass the request without modifying
        }

        // If the user is authenticated, add the Authorization header
        if (accessToken) {
            request = request.clone({
                setHeaders: {
                    accept: '*/*',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        }

        // If the request matches the target APIs, add an additional API_KEY header
        if (isTargetApi) {
            request = request.clone({
                setHeaders: {
                    kubaka_api_key: environment.API_KEY, // Use your API_KEY from the environment file
                },
            });
        }

        // Pass the modified request to the next handler
        return next.handle(request);
    }
}
// private readonly apiList = [
//     'auth/login',
//     'auth/email/verify',
//     'auth/forgot-password',
//     'auth/reset-password',
//     'auth/register',
//     'auth/createStaff',
//     'auth/resent-verification',
//     'auth/change-password',
//     'user-management/role',
//     'user-management/users'
// ];



// constructor(
//     private tokenService: TokenService,
// ) { }

// intercept(
//     request: HttpRequest<any>,
//     next: HttpHandler
// ): Observable<HttpEvent<any>> {
//     let currentUser: any = this.tokenService.getToken();
//     const isTargetApi = this.apiList.some((api) => request.url.startsWith(api));
//     console.log(currentUser);
//     const targetApi = 'https://api-gatway.kubaka.gov.rw/3001/auth/login';
//     if (currentUser && currentUser.data.token && currentUser.data?.token?.access_token) {
//         request = request.clone({
//             setHeaders: {
//                 'accept': '*/*',
//                 'Content-Type': 'application/json',
//                 Authorization: `Bearer ${currentUser.data?.token?.access_token}`,
//             },
//         });
//     }

//     if (isTargetApi) {
//         // Clone the request and add headers
//         const modifiedRequest = request.clone({
//             setHeaders: {
//                 'kubaka_api_key': environment.API_KEY, // Add your header key-value pair here
//             },
//         });

//         return next.handle(modifiedRequest);
//     }
//     return next.handle(request);

// if (request.url.startsWith(targetApi)) {
//     // Clone the request to add new headers
//     const modifiedRequest = request.clone({
//         setHeaders: {
//             'kubaka_api_key': environment.API_KEY, // Add your header key-value pair here
//         },
//     });

//     // Pass the modified request to the next handler
//     return next.handle(modifiedRequest);
// }




//     constructor(
//         private tokenService: TokenService,
//     ) { }

//     intercept(
//         request: HttpRequest<any>,
//         next: HttpHandler
//     ): Observable<HttpEvent<any>> {
//         let currentUser: any = this.tokenService.getToken();



//         console.log(currentUser);
//         const targetApi = 'https://api-gatway.kubaka.gov.rw/3001/auth/login';
//         if (currentUser && currentUser.data.token && currentUser.data?.token?.access_token) {
//             request = request.clone({
//                 setHeaders: {
//                     'accept': '*/*',
//                     'Content-Type': 'application/json',
//                     Authorization: `Bearer ${currentUser.data?.token?.access_token}`,
//                 },
//             });
//         } else

//             if (request.url.startsWith(targetApi)) {
//                 // Clone the request to add new headers
//                 const modifiedRequest = request.clone({
//                     setHeaders: {
//                         'kubaka_api_key': '13C119551E1FA51BF6C293CB17782', // Add your header key-value pair here
//                     },
//                 });

//                 // Pass the modified request to the next handler
//                 return next.handle(modifiedRequest);
//             }
//         return next.handle(request);
//     }
// }
