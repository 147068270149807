<div class="">
  <div class="kbk-userprofile">
    <!-- <div class="kbk-userprofile-avtar">
            <img src="assets/imgs/profile1.svg" alt="profile picture" />
        </div> -->
    <div class="kbk-userprofile-info">
      <span class="names">Project Name: {{ inputData.projects.projectName }} </span>
      <span class="title">Address: {{ inputData.projects.provinceName | titlecase }}, {{ inputData.projects.districtName
        | titlecase }} ,{{ inputData.projects.sectorName | titlecase }}, {{ inputData.projects.cellName | titlecase }},
        {{ inputData.projects.villageName | titlecase }}</span>
    </div>
  </div>
  <div class="kbk-table">
    <div class="kbk-table_hder p-cont">
      <!-- <span class="hder" aria-label="header tittle">Users</span> -->
      <div class="btns sp-xs">
        <!-- <button type="button" class="kbk-btn kbk-btn-main" data-bs-toggle="modal" id="create-btn"
                        data-bs-target="#showModal" (click)="openModal(content)">Create User</button> -->
      </div>
    </div>
    <div class="kbk-table_body">
      <!-- Nav tabs -->
      <ul ngbNav #nav="ngbNav" [activeId]="1" class="kbk-tabs">
        <li [ngbNavItem]="1" class="kbk-tabs-item">
          <!-- <a ngbNavLink class="kbk-tabs-link"> All User </a> -->
          <ng-template ngbNavContent>
            <div class="kbk-table_flt">
              <div class="form-input">
                <div class="form-input_search fsm">
                  <input type="text" name="searchTerm" placeholder="Search for something..."
                    [(ngModel)]="userService.searchTerm" />
                  <button type="button" class="btn">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <g data-name="Layer 2">
                        <g data-name="search">
                          <rect width="24" height="24" opacity="0" />
                          <path
                            d="M20.71 19.29l-3.4-3.39A7.92 7.92 0 0 0 19 11a8 8 0 1 0-8 8 7.92 7.92 0 0 0 4.9-1.69l3.39 3.4a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42zM5 11a6 6 0 1 1 6 6 6 6 0 0 1-6-6z" />
                        </g>
                      </g>
                    </svg>
                  </button>
                </div>
              </div>
              <!-- <div class="form-input">
                                    <div>
                                        <select class="fsm" data-choices data-choices-search-false
                                            name="choices-single-default" id="idStatus"
                                            [(ngModel)]="userService.status">
                                            <option value="" selected>Filter by Role</option>
                                        </select>
                                    </div>
                                </div>
                                <button type="button" class="kbk-btn kbk-btn-main kbk-btn-sm">Filter</button> -->
            </div>
            <div class="kbk-table-responsive">
              <table>
                <thead>
                  <tr>
                    <th scope="col" style="width: 25px">
                      <label class="form-checkbox form-checkbox-dft">
                        <input type="checkbox" id="checkAll" value="option" [(ngModel)]="masterSelected"
                          (change)="checkUncheckAll($event)" />
                      </label>
                    </th>
                    <th data-sort="firstName">Firstname</th>
                    <th data-sort="lastName">Lastname</th>
                    <th data-sort="role">Role</th>
                    <th data-sort="role">Email</th>
                    <th data-sort="isActive">Active</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="
                      let data of lists | genericfilter : userService.searchTerm
                    " id="o_{{ data._id }}">
                    <td scope="row">
                      <label class="form-checkbox form-checkbox-dft">
                        <input type="checkbox" name="checkAll" value="{{ data._id }}" [(ngModel)]="data.state"
                          (change)="onCheckboxChange($event)" />
                      </label>
                    </td>
                    <td>
                      <ngb-highlight [result]="data.firstName | titlecase" [term]="userService.searchTerm">
                      </ngb-highlight>
                    </td>
                    <td>
                      <ngb-highlight [result]="data.lastName | titlecase" [term]="userService.searchTerm">
                      </ngb-highlight>
                    </td>
                    <td>
                      <ngb-highlight [result]="data.role.name | titlecase" [term]="userService.searchTerm">
                      </ngb-highlight>
                    </td>
                    <td>
                      <ngb-highlight [result]="data.role.email | titlecase" [term]="userService.searchTerm">
                      </ngb-highlight>
                    </td>
                    <td>
                      <ngb-highlight [result]="data.isActive ? 'Active' : 'Deactivated'"
                        [term]="userService.searchTerm"></ngb-highlight>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="pagnation">
              <div class="pagnation-item">
                <div class="pagnation-dt" id="tickets-table_info" role="status" aria-live="polite">
                  <span class="ent">{{ totalRecords }}</span>
                  <span class="cur">{{ startIndex }} - {{ endIndex }}</span>
                </div>
              </div>
              <div class="pagnation-item">
                <ngb-pagination [collectionSize]="total" [(page)]="page" [pageSize]="pageSize"
                  (pageChange)="getPremiumData()">
                </ngb-pagination>
              </div>
            </div>
          </ng-template>
        </li>
      </ul>
      <!-- Tab panes -->
      <div class="tab-content text-muted">
        <div [ngbNavOutlet]="nav"></div>
        <div id="elmLoader">
          <div class="load-cont" role="status">
            <div class="preload">
              <div class="line-cont">
                <span class="line1"></span>
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="req-card">
            <h3>Parcel Details</h3>
            <div class="form-out">
                <label>PLOT Number / UPI</label>
                <span>{{inputData.projects.upi}}</span>
            </div>
            <div class="form-out">
                <label>Project name</label>
                <span>{{inputData?.projects?.projectName}}</span>
            </div>
            <div class="form-out">
                <label>Planned for</label>
                <span>{{inputData?.projects.selectedUse}}</span>
            </div>
            <div class="form-out">
                <label>Location</label>
                <div class="kbk-x sp-sm">
                    <span>{{inputData?.projects?.provinceName}}</span>
                    <span>{{inputData?.projects?.districtName}}</span>
                    <span>{{inputData?.projects?.sectorName}}</span>
                    <span>{{inputData?.projects?.cellName}}</span>
                    <span>{{inputData?.projects?.villageName}}</span>
                </div>
            </div>
        </div>
        <div class="req-card">
            <h3>Application Details</h3>
            <div class="form-out">
                <label>Request from</label>
                <span>{{inputData?.userDetails?.firstName}}</span>
            </div>
            <div class="form-out">
                <label>Plot Size</label>
                <span>{{inputData?.projects?.plotSize}}</span>
            </div>
            <div class="form-out">
                <label>Build Up Area</label>
                <span>{{inputData?.projects?.buildUpArea}}</span>
            </div>
            <div class="form-out">
                <label>Application Date</label>
                <span>{{inputData?.created_at | date}}</span>
            </div>
        </div> -->
  <div class="kbk-x-c sp-sm mt-md">
    <button type="button" class="kbk-btn kbk-btn-error" (click)="close()"> Cancel </button>
    <button type="button" class="kbk-btn kbk-btn-success" (click)="submit('approve')"> Submit </button>
  </div>
</div>