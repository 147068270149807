<div class="modol-content">
    <form (ngSubmit)="saveUser()" [formGroup]="userForm" autocomplete="off">
      <div class="form-set">
        <div class="form-input">
          <label>Application Name</label>
          <div>
            <input type="text" name="applicationName" formControlName="applicationName" required />
          </div>
        </div>
        <div class="form-input">
          <label>UPI</label>
          <div>
            <input type="text" name="UPI" formControlName="UPI" required />
          </div>
        </div>
        <div class="form-input">
          <label>Agency</label>
          <div>
            <select name="agencyId" id="agencyId" formControlName="agencyId">
              <option disabled value="">Choose agency</option>
              <option *ngFor="let r of agencies" [value]="r.id">
                {{ r.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-input">
            <label>Application status

            </label>
            <div>
              <select name="applicationStatusId" id="applicationStatusId" formControlName="applicationStatusId">
                <option disabled value="">Choose</option>
                <option *ngFor="let r of applicationStatuses" [value]="r.id">
                  {{ r.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-input">
            <label>Build Type
                
            </label>
            <div>
              <select name="buildTypeId" id="buildTypeId" formControlName="buildTypeId">
                <option disabled value="">Choose</option>
                <option *ngFor="let r of buildTypes" [value]="r.id">
                  {{ r.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-input">
            <label>Category Type
                
            </label>
            <div>
              <select name="categoryTypeId" id="categoryTypeId" formControlName="categoryTypeId">
                <option disabled value="">Choose</option>
                <option *ngFor="let r of categoryTypes" [value]="r.id">
                  {{ r.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="form-input">
            <label>Permit Type
                
            </label>
            <div>
              <select name="permitTypeId" id="permitTypeId" formControlName="permitTypeId">
                <option disabled value="">Choose</option>
                <option *ngFor="let r of permitTypes" [value]="r.id">
                  {{ r.name }}
                </option>
              </select>
            </div>
          </div>
          
      </div>
      <div class="kbk-x-c sp-sm mt-md">
        <button type="button" class="kbk-btn kbk-btn-sec">Cancel</button>
        <button type="submit" class="kbk-btn kbk-btn-main">Search</button>
      </div>
    </form>
  </div>