import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';

@Directive({
  selector: '[appCurrencyFormatter]'
})
export class CurrencyFormatterDirective {

  private lastValue: string | null = null;

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event: Event) {
    const input = this.el.nativeElement;
    let value = input.value.replace(/[^0-9.]/g, '');  // Strip out any non-numeric characters

    // Format the value as currency
    const formattedValue = this.formatCurrency(value);

    if (this.lastValue !== formattedValue) {
      input.value = formattedValue;
      this.lastValue = formattedValue;
    }
  }

  formatCurrency(value: string): string {
    if (!value) {
      return '';
    }

    const numberValue = parseFloat(value);

    if (isNaN(numberValue)) {
      return '';
    }

    // Format the number as currency using the built-in `toLocaleString` method
    return numberValue.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  }
}


@Directive({
  selector: '[noNegative]'
})
export class NoNegativeDirective {
  constructor(private ngControl: NgControl) { }

  @HostListener('input', ['$event'])
  onInputChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    let value = input.value;

    // Remove any characters that are not digits or a decimal point
    value = value.replace(/[^0-9.]/g, '');

    // Ensure only one decimal point is allowed
    const decimalIndex = value.indexOf('.');
    if (decimalIndex !== -1) {
      value = value.substring(0, decimalIndex + 1) + value.substring(decimalIndex + 1).replace(/\./g, '');
    }

    // Update the input value in the control
    this.ngControl.control?.setValue(value);
  }
}


// @Directive({
//   selector: '[appCommaSeparator]'
// })
// export class CommaSeparatorDirective {

//   private lastValue: string | null = null;

//   constructor(private el: ElementRef) { }

//   @HostListener('input', ['$event']) onInputChange(event: Event) {
//     const input = this.el.nativeElement;
//     let value = input.value.replace(/,/g, '');  // Remove any existing commas

//     // Only process the value if it's a valid number
//     if (!isNaN(Number(value)) && value !== '') {
//       // Format the number with commas
//       const formattedValue = this.formatNumber(value);

//       if (this.lastValue !== formattedValue) {
//         input.value = formattedValue;
//         this.lastValue = formattedValue;
//       }
//     } else {
//       input.value = '';  // Clear input if the value is not a valid number
//     }
//   }

//   formatNumber(value: string): string {
//     // Convert the value to a string, add commas
//     return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
//   }





// }




@Directive({
  selector: '[appNoSpaces]'
})
export class NoSpacesDirective {
  @HostListener('input', ['$event']) onInputChange(event: any) {
    const initialValue = event.target.value;
    event.target.value = initialValue.replace(/\s/g, '');
    if (initialValue !== event.target.value) {
      event.stopPropagation();
    }
  }
}


@Directive({
  selector: '[appCommaSeparator]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CommaSeparatorDirective,
      multi: true,
    },
  ],
})
export class CommaSeparatorDirective implements ControlValueAccessor {
  private lastValue: string | null = null;

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  // To write a value to the input field from the model
  writeValue(value: any): void {
    const stringValue = value != null ? value.toString() : '';
    const formattedValue = this.formatNumber(stringValue);
    this.renderer.setProperty(this.el.nativeElement, 'value', formattedValue);
    this.lastValue = formattedValue;
  }

  // Register a callback for when the input value changes
  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  // Register a callback for when the input is touched
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  // Set the disabled state for the input
  setDisabledState(isDisabled: boolean): void {
    this.renderer.setProperty(this.el.nativeElement, 'disabled', isDisabled);
  }

  private onChange: (value: any) => void = () => { };
  private onTouched: () => void = () => { };

  @HostListener('input', ['$event']) onInputChange(event: Event) {
    const input = this.el.nativeElement;
    let value = input.value.replace(/,/g, ''); // Remove any existing commas

    if (!isNaN(Number(value)) && value !== '') {
      const formattedValue = this.formatNumber(value);

      if (this.lastValue !== formattedValue) {
        this.renderer.setProperty(input, 'value', formattedValue);
        this.lastValue = formattedValue;

        // Notify Angular about the raw numeric value
        this.onChange(Number(value));
      }
    } else {
      input.value = ''; // Clear input if the value is invalid
      this.onChange(null); // Notify Angular of invalid value
    }
  }

  @HostListener('blur') onBlur() {
    this.onTouched();
  }

  formatNumber(value: string): string {
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ','); // Add commas to the number
  }
}