import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
// import { Crisp } from "crisp-sdk-web";
import { AppConfig } from './app.config';
import { NOTIFICATION_COLOR, UtilService } from './shared/services/util.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    upiFinder = '';
    showChat: boolean = false;
    toggleIcon: string = 'open'; // Initially set to open icon
    toggleChat() {
        this.showChat = !this.showChat;
        this.toggleIcon = this.showChat ? 'close' : 'open'; // Change the icon
        console.log(this.showChat);
    }

    constructor(
        private translateService: TranslateService,
        private utilService: UtilService,
        private appConfig: AppConfig) {
        // const userLang = navigator.language || 'en';
        // const languageCode = userLang.split('-')[0];
        // this.translateService.setDefaultLang(languageCode);
        // this.translateService.use(languageCode);

        translateService.addLangs(['en', 'kin', 'fr']);
        translateService.setDefaultLang('en');

        // this.utilService.showNotification(NOTIFICATION_COLOR.error, "Invalid username or password", "bottom", "center");


        // Crisp.configure("b8fb124c-627f-474c-a457-6624926dc4e1");
    }




}