import { Component, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { APIURLPATH } from 'src/app/shared/services/url-path';
import { UserMgtService } from 'src/app/user-management/services/user-mgt.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-general-search',
  templateUrl: './general-search.component.html',
  styleUrls: ['./general-search.component.scss']
})
export class GeneralSearchComponent {
  userForm!: UntypedFormGroup;
  @Output() backToParent = new EventEmitter;
  agencies: any[] = []
  categoryTypes: any[] = []
  buildTypes: any[] = []
  applicationStatuses: any[] = []
  permitTypes: any[] = []

  constructor(
    private formBuilder: UntypedFormBuilder,
    public userService: UserMgtService,
  ) {
    this.userService.findAllWithPath(environment.authUrl + APIURLPATH.AGENCIES)
      .subscribe(data => {
        this.agencies = data;
      });
    this.userService.findAllWithPath(environment.applicationUrl + 'application/applicationStatus')
      .subscribe(data => {
        this.applicationStatuses = data;
      });
    this.userService.findAllWithPath(environment.applicationUrl + APIURLPATH.BUILDING_TYPE)
      .subscribe(data => {
        this.buildTypes = data;
      });
    this.userService.findAllWithPath(environment.applicationUrl + APIURLPATH.CATEGORYTYPE)
      .subscribe(data => {
        this.categoryTypes = data;
      });

    this.userService.findAllWithPath(environment.applicationUrl + APIURLPATH.PERMITYPES)
      .subscribe(data => {
        this.permitTypes = data;
      });
  }
  ngOnInit(): void {
    this.userForm = this.formBuilder.group({
      agencyId: [""],
      applicationName: [''],
      UPI: [''],
      applicationStatusId: [''],
      permitTypeId: [''],
      categoryTypeId: [''],
      buildTypeId: [''],
    });
  }



  saveUser() {
    this.backToParent.emit(this.userForm.value);
  }
}
