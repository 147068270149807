<div class="app-dash" *ngIf="!isNewInspection">
    <div class="container">
        <div class="app-main">
            <div class="app-welnonte">
                <div class="app-welnote_dtails">
                </div>
            </div>
            <div class="app-lists">
                <div class="app-tblist">
                    <div class="app-tblist_title">
                        <span class="hder" aria-label="header tittle">Inspections</span>
                        <div class="btns">
                            <div class="form-input clear-m w-aut">
                                <div class="form-input_search">
                                    <input type="text" name="searchTerm" [(ngModel)]="applicationService.searchTerm"
                                        (ngModelChange)="onSearchTermChange($event)"
                                        placeholder="Search for something...">
                                    <button type="button" class="btn">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                            <g data-name="Layer 2">
                                                <g data-name="search">
                                                    <rect width="24" height="24" opacity="0" />
                                                    <path
                                                        d="M20.71 19.29l-3.4-3.39A7.92 7.92 0 0 0 19 11a8 8 0 1 0-8 8 7.92 7.92 0 0 0 4.9-1.69l3.39 3.4a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42zM5 11a6 6 0 1 1 6 6 6 6 0 0 1-6-6z" />
                                                </g>
                                            </g>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            <a class="kbk-btn kbk-btn-sec brtn-edth" data-bs-toggle="modal" id="create-btn"
                                data-bs-target="#showModal" (click)="closeList()">Close</a>
                            <a class="kbk-btn kbk-btn-main brtn-edth" data-bs-toggle="modal" id="create-btn"
                                data-bs-target="#showModal" (click)="newInspection('')">New</a>
                        </div>
                    </div>
                    <ul class="tblist" *ngIf="lists.length > 0">
                        <li class="tblist-item" *ngFor="let li of lists">
                            <div class="tblist-item_icon">
                                <img src="assets/ikons/colored/ikon-file-blue.svg" alt="" />
                            </div>
                            <div class="tblist-item_dt">
                                <span>
                                    <span class="ttl">Geotechnical Report Respected</span>
                                    {{li.geotechnicalReportRespected=== 'true' ? 'Yes' : 'No'}}</span>
                                <span>
                                    <span class="ttl">Foundation Type</span> {{li.foundationType=== 'true' ? 'Yes' :
                                    'No'}}</span>
                            </div>
                            <div class="tblist-item_dt txt-l">
                                <span>
                                    <span class="ttl">Procedures Comply</span> {{li.proceduresComply=== 'true' ? 'Yes' :
                                    'No'}} </span>
                                <span>
                                    <span class="ttl">Soil Treatment Comply</span> {{li.soilTreatmentComply=== 'true' ?
                                    'Yes' : 'No'}}</span>
                            </div>
                            <div class="tblist-item_dt">
                                <span>
                                    <span class="ttl">Erosion Control Measures</span> {{li.erosionControlMeasures===
                                    'true' ? 'Yes' : 'No'}}</span>
                                <span>
                                    <span class="ttl">Footing Size Comply</span> {{li.footingSizeComply=== 'true' ?
                                    'Yes' : 'No'}}</span>
                            </div>
                            <div class="tblist-item_dt">
                                <span>
                                    <span class="ttl">Location Comply</span> {{li.locationComply=== 'true' ? 'Yes' :
                                    'No'}}</span>
                            </div>
                            <div class="tblist-item_dt">
                                <span>
                                    <span class="ttl">Reinforcement Size Comply</span> {{li.reinforcementSizeComply ===
                                    'true' ? 'Yes' : 'No'}}</span>
                            </div>
                            <div class="tblist-item_dt">
                                <span>
                                    <span class="ttl">Spacing Reinforcements Comply</span>
                                    {{li.spacingReinforcementsComply === 'true' ? 'Yes' : 'No'}}</span>
                            </div>
                            <div class="tblist-item_dt">
                                <span>
                                    <span class="ttl">Concrete Placement Comply</span> {{li.concretePlacementComply===
                                    'true' ? 'Yes' : 'No'}}</span>
                            </div>
                            <div class="tblist-item_dt">
                                <span>
                                    <span class="ttl">Plumbing Comply</span> {{li.plumbingComply === 'true' ? 'Yes' :
                                    'No'}}</span>
                            </div>
                            <div class="tblist-item_dt">
                                <span>
                                    <span class="ttl">Comment</span> {{li.comments}}</span>
                            </div>
                            <div class="tblist-item_dt">
                                <span>
                                    <span class="ttl">reinforcementSizeComply</span>
                                    {{li.reinforcementSizeComply}}</span>
                            </div>
                            <!-- <div class="tblist-item_status">
                                <span class="bdg bdg-pend">{{li.reinforcementSizeComply}}</span>
                            </div>
                            <div class="tblist-item_xcn" *ngIf="lists.length > 0">
                                <a class="kbk-link-btn hs-tp" data-kbk-tooltip="Related Application" id="create-btn">
                                    Edit </a>
                            </div> -->
                        </li>
                    </ul>
                    <div class="pagnation" *ngIf="lists.length > 0">
                        <div class="pagnation-item">
                            <div class="pagnation-dt" id="tickets-table_info" role="status" aria-live="polite">
                                <span class="ent">{{totalRecords}}</span> <span class="cur">{{startIndex}} -
                                    {{endIndex}}</span>
                            </div>
                        </div>
                        <div class="pagnation-item">
                            <ngb-pagination [collectionSize]="totalRecords" [(page)]="page" [pageSize]="pageSize"
                                (pageChange)="getPremiumData();">
                            </ngb-pagination>
                        </div>
                    </div>
                    <ul class="tblist" *ngIf="lists.length === 0">
                        <li class="tblist-item">
                            <h2 *ngIf="lists.length === 0" id="exampleModalLabel" class="no-rcrds-found">No records
                                found</h2>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="app-gen appl-details" *ngIf="isNewInspection">
    <div class="app-gen-header genvline">
        <section class="genline">
            <div>
                <span class="hder" aria-label="header tittle">Inspection of Foundation Works</span>
                <span class="hder-sm" aria-label="header tittle">1. Site identification and Contractor details</span>
            </div>
        </section>
        <form [formGroup]="inspectionForm" (ngSubmit)="onSubmit()">
            <div class="step-panel_body">
                <div class="form-set">
                    <div>
                        <label for="geotechnicalReport">Geotechnical Report Respected?</label>
                        <select formControlName="geotechnicalReportRespected">
                            <option value="" disabled>Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div>
                        <label for="foundationType">Type of Foundation</label>
                        <select formControlName="foundationType">
                            <option value="" disabled>Select</option>
                            <option value="spreadFooting">Spread/Isolated Footing</option>
                            <option value="stripFooting">Strip Footing</option>
                            <option value="padFoundation">Pad Foundation</option>
                            <option value="raftFoundation">Mat/Raft Foundation</option>
                            <option value="pileFoundation">Pile Foundation</option>
                            <option value="other">Other</option>
                        </select>
                    </div>
                    <div>
                        <label for="proceduresComply">Procedure Complies?</label>
                        <select formControlName="proceduresComply">
                            <option value="" disabled>Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                </div>
                <div class="form-set">
                    <div>
                        <label for="soilTreatmentComply">Soil Treatment Complies?</label>
                        <select formControlName="soilTreatmentComply">
                            <option value="" disabled>Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div>
                        <label for="erosionControlMeasures">Erosion Control Measures in Place?</label>
                        <select formControlName="erosionControlMeasures">
                            <option value="" disabled>Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div>
                        <label for="footingSizeComply">Footing Size Complies?</label>
                        <select formControlName="footingSizeComply">
                            <option value="" disabled>Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                </div>
                <div class="form-set">
                    <div>
                        <label for="locationComply">Location Complies?</label>
                        <select formControlName="locationComply">
                            <option value="" disabled>Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div>
                        <label for="reinforcementSizeComply">Reinforcement Size Complies?</label>
                        <select formControlName="reinforcementSizeComply">
                            <option value="" disabled>Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div>
                        <label for="spacingReinforcementsComply">Spacing of Reinforcements Complies?</label>
                        <select formControlName="spacingReinforcementsComply">
                            <option value="" disabled>Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                </div>
                <div class="form-set">
                    <div>
                        <label for="concretePlacementComply">Concrete Placement Complies?</label>
                        <select formControlName="concretePlacementComply">
                            <option value="" disabled>Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div>
                        <label for="plumbingComply">Plumbing Complies?</label>
                        <select formControlName="plumbingComply">
                            <option value="" disabled>Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                </div>
                <div class="form-set">
                    <div>
                        <label for="comments">Comments</label>
                        <textarea formControlName="comments"></textarea>
                    </div>
                </div>
                <div class="form-set">
                    <div>
                        <div class="form-input">
                            <app-input-file-upload (fileUpload)="getFileToSave($event)"></app-input-file-upload>
                        </div>
                    </div>
                </div>
            </div>
            <div class="step-panel_footer">
                <button class="kbk-btn kbk-btn-sec" type="button" (click)="cancel()">Cancel</button>
                <button class="kbk-btn kbk-btn-main" type="submit">Submit </button>
            </div>
        </form>
    </div>
</div>